
import $ from 'jquery';

export function scrollToPosition(position = 0, speed = 2) {

	const $html = $('html, body');
	const currentScroll = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
	const distance = Math.abs(currentScroll - position);
	const time = distance/speed;

	$html.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function() {
		$html.stop();
	});

	$html.animate({ scrollTop: position }, time, function() {
		$html.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
	});
}

export function scrollTo(element, speed, horizontal = false) {

	if (element instanceof jQuery) element = element.get(0);
	if (!element || !element.nodeType) return false;

	var rect = element.getBoundingClientRect();

	// get top/left relative to document, not window
	var top = rect.top + (window.scrollY  || window.pageYOffset || document.documentElement.scrollTop) - document.documentElement.clientTop;
	scrollToPosition(top, speed);
}


export default {
	scrollToPosition,
	scrollTo
}